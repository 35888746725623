import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    icon: "bx-home-circle",
    link: "/home",
    role: "admin",
    permission: "view_dashboard_main_page",
  },

  {
    id: 2,
    label: "Users",
    link: "/users",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_users",
  },

  {
    id: 3,
    label: "Property Owners",
    link: "/owners",
    icon: "bx bxs-user-rectangle",
    role: "admin",
    permission: "view_users",
  },

  {
    id: 5,
    label: "Pages",
    link: "/pages",
    icon: "bx bx-book-open",
    role: "admin",
    permission: "view_pages",
  },

  {
    id: 8,
    label: "Currencies",
    link: "/currencies",
    icon: "bx bx-dollar-circle",
    role: "admin",
    permission: "view_currency",
  },
  {
    id: 9,
    label: "Roles",
    link: "/roles",
    icon: "bx bx-lock-alt",
    role: "admin",
    permission: "view_privileges",
  },
  {
    id: 10,
    label: "Management",
    link: "/management",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_users",
  },
  {
    id: 11,
    label: "Categories",
    link: "/categories",
    icon: "bx bx-book-bookmark",
    role: "admin",
    permission: "view_categories",
  },
  {
    id: 12,
    label: "Mobile Home Ads",
    link: "/sliders",
    icon: "bx bx bx-images",
    role: "admin",
    permission: "view_slider",
  },
  {
    id: 13,
    label: "Services",
    link: "/services",
    icon: "bx bxl-slack-old",
    role: "admin",
    permission: "view_services",
  },
  {
    id: 13,
    label: "Reservations Cancelation Reasons",
    link: "/cancelationReasons",
    icon: "bx bx-poll",
    role: "admin",
    permission: "view_cancellation_reasons",
  },
  {
    id: 13,
    label: "Ratings Grievances",
    link: "/reviews-disputes",
    icon: "bx bxs-shield-alt-2",
    role: "admin",
    permission: "view_review_disputes",
  },
  {
    id: 14,
    label: "Banks",
    link: "/banks",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_banks",
  },
  {
    id: 15,
    label: "Bank Accounts",
    link: "/bank-accounts",
    icon: "bx bxs-bank",
    role: "admin",
    permission: "view_banks",
  },
  {
    id: 16,
    label: "Payments",
    link: "/payments",
    icon: "bx bxl-visa",
    role: "admin",
    permission: "view_payment_methods",
  },
  {
    id: 17,
    label: "Reservations",
    link: "/reservations",
    icon: "bx bx-calendar-check",
    permission: "view_reservations",
    badge: {
      variant: "danger pending-reservations-counter-id",
      text: "0",
    },
  },
  {
    id: 18,
    label: "Facilities",
    link: "/properties",
    icon: "bx bx-buildings",
    permission: "view_property",
    badge: {
      variant: "danger new-facilities-counter-id",
      text: "0",
    },
  },
  {
    id: 19,
    label: "Coupons Management",
    link: "/coupons",
    icon: "bx bxs-offer",
    permission: "view_coupon",
  },
  {
    id: 20,
    label: "Transactions History",
    link: "/transactions",
    icon: "bx bxs-dollar-circle",
    permission: "view_transaction_history",
  },
  {
    id: 32,
    label: "Canceled Reservations History",
    link: "/canceled-reservations-history",
    icon: "bx bxs-calendar-x",
    permission: "view_settings",
  },
  {
    id: 21,
    label: "Refund Management",
    link: "/refund-managment",
    icon: "bx bx-repost",
    permission: "view_refund_requests",
    badge: {
      variant: "danger refund-requests-counter-id",
      text: "0",
    },
  },
  {
    id: 22,
    label: "Owners Payouts",
    link: "/payouts-managment",
    icon: "bx bx-transfer-alt",
    permission: "view_payout_requests",
    badge: {
      variant: "danger owner-payouts-counter-id",
      text: "0",
    },
  },
  {
    id: 23,
    label: "Chats Management",
    link: "/chat",
    icon: "bx bx-chat",
    role: "admin",
    permission: "view_chats",
  },
  {
    id: 24,
    label: "Notifications",
    icon: "bx bx-bell",
    link: "/notification",
    role: "admin",
    permission: "view_notifications",
  },
  {
    id: 25,
    label: "System Transactions",
    icon: "bx bx-news",
    link: "/system-transactions",
    role: "admin",
    permission: "view_system_transactions",
  },

  {
    id: 27,
    label: "Owners Joining Requests",
    link: "/join-requests",
    icon: "bx bxs-bank",
    permission: "view_users",
    badge: {
      variant: "danger owner-requests-counter-id",
      text: "0",
    },
  },
  {
    id: 28,
    label: "Reviews",
    link: "/all-reviews",
    icon: "bx bx-line-chart",
    permission: "view_reviews",
  },
  {
    id: 29,
    label: "System Logs",
    link: "/system-logs",
    icon: "bx bxs-user-detail",
    permission: "view_settings",
  },
  {
    id: 33,
    label: "Settings",
    link: "/settings",
    icon: "bx bx-cog ",
    permission: "view_settings",
  },

  {
    id: 30,
    label: "Reports",
    icon: "bx bxs-report",

    subItems: [
      {
        id: 301,
        label: "Payment Methods Stats",
        link: "/reports/payment-methods",
        parentId: 30,
        permission: "view_payment_methods_stats",
      },
    ],
  },
  {
    id: 31,
    label: "Regions",
    icon: "bx-world",
    permission: "view_regions",

    subItems: [
      {
        id: 311,
        label: "Countries",
        link: "/regions",
        permission: "view_regions",
        parentId: 31,
      },
      {
        id: 312,
        label: "Mobile Home Cities",
        link: "/regions/mobile-home/cities",
        permission: "view_regions",
        parentId: 31,
      },
      {
        id: 312,
        label: "Capitals Cities",
        link: "/regions/capital/cities",
        permission: "view_regions",
        parentId: 31,
      },
    ],
  },
];
