import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-general-search',
  template: `
    <div class="search-container" [class]="currentLang">
      <input
          type="text"
          class="form-control search-filed"
          [(ngModel)]="keyword"
          [formControl]="searchField"
          [placeholder]="'Search On List...' | translate"
          (keydown.enter)="liveSearch($event.target.value)"
      />
    </div>
  `,
  styleUrls: ['./general-search.component.scss'],
})
export class GeneralSearchComponent implements OnInit, OnDestroy {
  @Input() allowLive = false;
  @Input() liveKey = '';
  @Input() keyword = '';
  @Output() typing = new EventEmitter<string>();
  searchField = new FormControl();
  currentLang;
  typingSub: Subscription;

  constructor(private router: Router, public translate: TranslateService) {}

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.typingSub = this.searchField.valueChanges.subscribe((value) => {
      this.typing.emit(value);
    });
  }

  liveSearch(value) {
    if (!this.allowLive) {
      return;
    }
    const qparams = { [this.liveKey]: value };
    this.router.navigate([], {
      queryParams: qparams,
      queryParamsHandling: 'merge',
    });
  }

  clearSearch() {
    this.keyword = '';
    this.typing.emit('');
    this.router.navigate([]);
  }

  ngOnDestroy(): void {
    this.typingSub.unsubscribe();
  }
}
