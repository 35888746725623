import { Injectable } from "@angular/core";
import { HttpClientService } from "../http/http.service";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  constructor(private http: HttpClientService) {}

  getSidebarCounts() {
    return this.http.get("settings/admin/badges");
  }
}
