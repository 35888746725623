import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { FormManage } from "src/app/core/helpers/formVaild";
import { IFilterData } from "src/app/core/models/filters.model";
import { IPaymentModel } from "src/app/core/models/payment.model";
import { IpropertyData } from "src/app/core/models/property.model";
import { ICityData, ICountryData } from "src/app/core/models/regions.models";
import { IUserData } from "src/app/core/models/user.model";
import { PaymentsService } from "src/app/core/services/payments/payments.service";
import { PropertiesService } from "src/app/core/services/property/property.service";
import { RegionsService } from "src/app/core/services/regions/regions.service";
import { UsersService } from "src/app/core/services/users/users.service";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
})
export class FiltersComponent extends FormManage implements OnInit, OnDestroy {
  filterForm: FormGroup;
  @Input() filterData: IFilterData;
  @Input() OPTIONS: string[];
  formSubscription: Subscription;
  showActions = false;

  properties = [];
  users = [];
  owners = [];
  countries = [];
  cities = [];
  payment_methods = [];

  constructor(
    private router: Router,
    private propertiesService: PropertiesService,
    private regionsService: RegionsService,
    private paymentService: PaymentsService,
    private usersService: UsersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    if (this.filterData) {
      this.setDataToForm(this.filterData);
    }
  }

  initForm() {
    this.filterForm = new FormGroup({});
    this.setForm(this.filterForm);

    // loop through the filter options and set form controllers
    if (this.OPTIONS.length > 0) {
      this.OPTIONS.forEach((key) => {
        this.addControllerTOTheForm(key, null);
      });
    }

    this.filterActionsHandler();
    this.getDataFromServer(this.filterForm.controls);
  }

  // function control show/hide of filter actions
  filterActionsHandler() {
    this.formSubscription = this.filterForm.valueChanges.subscribe((res) => {
      const formRes = { ...res };
      Object.keys(formRes).forEach((el) => {
        if (formRes[el] === null || formRes[el] === "") delete formRes[el];
      });

      if (Object.keys(formRes).length === 0) {
        this.showActions = false;
      } else {
        this.showActions = true;
      }
    });
  }

  getDataFromServer(formControls) {
    if (formControls.property_id) {
      this.getAllProperties();
    }

    if (formControls.user_id) {
      this.getAllUsers();
    }

    if (formControls.owner_id) {
      this.getAllOwners();
    }
    if (formControls.country_id) {
      this.getAllCountries();
    }
    if (formControls.city_id) {
      this.getAllCities();
    }
    if (formControls.payment_id) {
      this.getAllPayments();
    }
  }

  // properties
  _propertyOptions: IpropertyData = { return_all: 1 };
  getAllProperties() {
    this.propertiesService
      .getAllProperties(this._propertyOptions)
      .pipe(take(1))
      .subscribe((res) => (this.properties = res.data));
  }

  propertySearch(keyword) {
    this._propertyOptions.keyword = keyword;
    this.getAllProperties();
  }

  // users
  _userOptions: IUserData = { return_all: 1, type: "user" };
  getAllUsers() {
    this.usersService
      .getAllUsers(this._userOptions)
      .pipe(take(1))
      .subscribe((res) => (this.users = res.data));
  }

  usersSearch(keyword) {
    this._userOptions.keyword = keyword;
    this.getAllUsers();
  }

  // owners
  _ownerOptions: IUserData = { return_all: 1, type: "property_owner" };
  getAllOwners() {
    this.usersService
      .getAllUsers(this._ownerOptions)
      .pipe(take(1))
      .subscribe((res) => (this.owners = res.data));
  }

  ownersSearch(keyword) {
    this._ownerOptions.keyword = keyword;
    this.getAllOwners();
  }

  // countries
  _countriesOptions: ICountryData = { return_all: 1 };
  getAllCountries() {
    this.regionsService
      .getAllCountries(this._countriesOptions)
      .subscribe((res) => (this.countries = res.data));
  }

  countriesSearch(keyword) {
    this._countriesOptions.keyword = keyword;
    this.getAllCountries();
  }

  // cities
  _cityOptions: ICityData = { return_all: 1 };
  getAllCities() {
    this.regionsService
      .getAllCities(this._cityOptions)
      .subscribe((res) => (this.cities = res.data));
  }
  citySearch(keyword) {
    this._cityOptions.keyword = keyword;
    this.getAllCities();
  }

  // payments
  _paymentOptions: IPaymentModel = { return_all: 1 };
  getAllPayments() {
    this.paymentService
      .getAllPaymentData(this._paymentOptions)
      .subscribe((res) => (this.payment_methods = res.data));
  }

  Submit() {
    const filterOptions = { ...this.filterForm.value };

    // REMOVE the empty properties from the object before navigate
    Object.keys(filterOptions).forEach((key) => {
      if (filterOptions[key] == "null" || filterOptions[key] == null) {
        delete filterOptions[key];
      }
    });

    this.router.navigate([], { queryParams: filterOptions });
  }

  reset() {
    this.filterForm.reset();
    this.router.navigate([], { queryParams: {} });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }
}
