// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { urlSettings } from "src/app/core/settings/urlSettings";
import { socketUrlSettings } from "src/app/core/settings/socketUrlSettings";

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAkGWHzE2XfMAU0MvDglbZd5MHW57GhLVs",
    authDomain: "rushroom-32ec5.firebaseapp.com",
    projectId: "rushroom-32ec5",
    storageBucket: "rushroom-32ec5.appspot.com",
    messagingSenderId: "748281383491",
    appId: "1:748281383491:web:5763bf0d3980bf1ae762f6",
    measurementId: "G-DN0EG7RSVZ",

    // databaseURL: "https://usoomat-f2825.firebaseio.com",
  },
};

urlSettings.Settings = {
  apiProtocol: "https",
  apiHost: "backend.rushrooms.co",
  apiEndPoint: "api",
  language: "ar",

  // apiProtocol: "https",
  // apiHost: "fada-41-44-147-164.ngrok-free.app",
  // apiEndPoint: "api",
  // language: "ar",

  // apiPort: 5000,
  // apiProtocol: "http",
  // apiHost: "192.168.1.11",
  // apiEndPoint: "api",
  // language: "ar",
};

socketUrlSettings.Settings = {
  apiProtocol: "https",
  apiHost: "backend.rushrooms.co",
  apiEndPoint: "api",
  language: "ar",
  apiPort: 2112,

  // apiProtocol: "https",
  // apiHost: "ef1c-197-58-255-150.ngrok-free.app",
  // apiEndPoint: "api",
  // language: "ar",

  // apiPort: 5000,
  // apiProtocol: "http",
  // apiHost: "192.168.1.11",
  // apiEndPoint: "api",
  // language: "ar",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
