import { Injectable } from "@angular/core";
import { HttpClientService } from "../http/http.service";
import { ICountryData, ICityData } from "../../models/regions.models";

@Injectable({
  providedIn: "root",
})
export class RegionsService {
  constructor(private http: HttpClientService) {}

  getAllCountries(ICountryData: ICountryData) {
    return this.http.get("settings/countries", ICountryData);
  }
  createCountry(body) {
    return this.http.postFormData(body, "settings/countries/create");
  }
  getSingleCountry(countryId: number) {
    return this.http.get(`settings/countries/${countryId}`);
  }
  updateCountry(body: any, countryId: number) {
    return this.http.postFormData(body, `settings/countries/${countryId}/edit`);
  }
  deleteCountry(countryId: number) {
    return this.http.post({}, `settings/countries/${countryId}/delete`);
  }
  countryActivation(id) {
    return this.http.post({}, `settings/countries/${id}/activation`);
  }
  // ////////////////////////////////////////////////////////////
  getAllCities(ICityData: ICityData) {
    return this.http.get("settings/countries/cities", ICityData);
  }

  createCities(body) {
    return this.http.postFormData(body, "settings/countries/create-state");
  }

  updateCity(body, cityId: number) {
    return this.http.postFormData(
      body,
      `settings/countries/${cityId}/edit-state`
    );
  }

  reorderCity(body) {
    return this.http.post(body, `settings/countries/reorder-state`);
  }

  deleteCity(cityId: number) {
    return this.http.post({}, `settings/countries/${cityId}/delete-state`);
  }

  getCitiesByCountryID(countryId: number, options: ICityData) {
    return this.http.get(`settings/countries/${countryId}/states`, options);
  }

  getSingleCity(cityId: number) {
    return this.http.get(`settings/countries/${cityId}/state`);
  }
  cityHomeAppeariance(id, body) {
    return this.http.post(body, `settings/countries/${id}/show-for-home`);
  }
}
