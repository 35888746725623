import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/core/services/auth/auth.service";
import { LanguageService } from "src/app/core/services/language/language.service";
import { CurrenciesService } from "src/app/core/services/currencies/currencies.service";
import { CachingService } from "src/app/core/services/caching/caching.service";
import { Observable } from "rxjs";
import { DisplayNotificationsService } from "src/app/core/services/display-notifications/display-notifications.service";
import { FirebaseMessagesService } from "src/app/core/services/firebaseMessages/firebase-messages.service";
import Swal from "sweetalert2";
import { SidebarService } from "src/app/core/services/sidebar/sidebar.service";
import { take } from "rxjs/operators";
type NotificationTypes = "general" | "reservation" | "property";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  openMobileMenu: boolean;
  currentuser;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  // language vars
  flagvalue;
  listLang;
  countryName;
  valueset;

  // currency vars
  currentCurrency;
  currenciesList = [];

  // notifcation vars
  message;
  notifications = [];
  readNotifications = [];
  public unreadNotifications$: Observable<number>;
  allNotifications = [];
  seeMore: boolean = true;
  canViewSettings = false;

  topbarCounts;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    private cachingService: CachingService,
    private currenciesService: CurrenciesService,
    private sidebarService: SidebarService,
    public translate: TranslateService,
    private displayNotifi: DisplayNotificationsService,
    private messagingService: FirebaseMessagesService
  ) {
    this.messagingService.currentMessage.subscribe((msg: any) => {
      this.message = msg;
      this.unreadNotifications$ = this.displayNotifi.unreadNotificationsSubject;
    });
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.initNotficationsSetup();

    this.listLang = this.languageService.listLang;
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this.cachingService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    this.cachingService.set("lang", this.cookieValue);
    if (this.cookieValue === "en") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }

    this.getUpdatedProfile();
    this.getCurrenciesList();

    this.getTobBarCounts();
    this.canViewSettings =
      this.authService.User.permissions.includes("view_settings");
  }

  initNotficationsSetup() {
    if (
      this.messagingService.firebaseToken &&
      !this.authService.User.firebase_token
    ) {
      const user = JSON.parse(this.cachingService.get("user"));
      user.firebase_token = this.messagingService.firebaseToken;
      this.cachingService.set("user", JSON.stringify(user));
    }

    this.displayNotifi.allowSeeMoreSub.subscribe((res) => (this.seeMore = res));
    this.getNotifications();
  }

  private getNotifications() {
    this.displayNotifi.notificationsSubject.subscribe((res) => {
      if (res) {
        this.allNotifications = res;
        this.setUnreadAndRead(res);
      }
    });
  }

  private setUnreadAndRead(allNotifications) {
    this.notifications = allNotifications.filter(
      (notification) => notification.read_at === null
    );
    this.readNotifications = allNotifications.filter(
      (notification) => notification.read_at !== null
    );
  }

  markAllNotificationsAsRead() {
    Swal.fire({
      title: this.translate.instant("Are You Sure !"),
      text: this.translate.instant("You will mark all notifications as read"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: this.translate.instant("No, Close"),
      confirmButtonText: this.translate.instant("Yes, Continue"),
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.translate.instant("Successfully completed"),
          this.translate.instant("All notifications have been marked as read"),
          "success"
        );
        this.displayNotifi.markAllNotificationsAsRead();
        if (this.notifications.length) {
          this.notifications.forEach((notification) => {
            notification.read_at = 1;
          });
          this.setUnreadAndRead(this.allNotifications);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant(""),
          this.translate.instant(
            "There has been no change in your notifications"
          ),
          "info"
        );
      }
    });
  }

  notificationTypes(type: NotificationTypes, id, notificationID) {
    this.markNotificationAsRead(notificationID);

    switch (type) {
      case "reservation":
        this.router.navigate(["/reservations", id]);
        break;
      case "property":
        this.router.navigate(["/properties", id]);
        break;
    }
  }

  markNotificationAsRead(notificationId: string) {
    this.displayNotifi.markNotificationAsRead(notificationId);
  }

  loadMoreNotifications() {
    this.displayNotifi.loadMoreNotifications();
  }

  getUpdatedProfile() {
    this.currentuser = this.authService.User.first_name;
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    this.cachingService.set("lang", lang);

    if (lang === "en") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }
    location.reload();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();

    this.router.navigate(["/account/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  getCurrenciesList() {
    if (this.currenciesService.currenciesList.length < 1) {
      this.currenciesService
        .getAllCurrencies({ return_all: 1 })
        .subscribe((res) => {
          this.currenciesService.currenciesList = res.data;
          this.currenciesList = res.data;
          this.cachingService.set("currenciesList", res.data);
          this.currenciesService.currenciesList = res.data;
          this.getCurrentCurrency();
        });
    } else {
      this.currenciesList = this.currenciesService.currenciesList;
      this.cachingService.set(
        "currenciesList",
        this.currenciesService.currenciesList
      );
      this.currenciesService.currenciesList =
        this.currenciesService.currenciesList;
      this.getCurrentCurrency();
    }
  }

  getCurrentCurrency() {
    this.currentCurrency = this.currenciesService.getCurrency();
    this.currenciesService.setCurrency(this.currentCurrency);
  }

  setCurrency(currency) {
    this.currentCurrency = currency;
    this.currenciesService.setCurrency(currency);
    location.reload();
  }

  getTobBarCounts() {
    this.sidebarService
      .getSidebarCounts()
      .pipe(take(1))
      .subscribe((res) => {
        this.topbarCounts = res.data;
        if (res.data.new_properties > 0 && this.router.url.includes("home")) {
          Swal.fire(
            this.translate.instant("Alert"),
            this.translate.instant("There Is New Products Need Your Review"),
            "info"
          );
        }
      });
  }
}
