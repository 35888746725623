import { Injectable } from "@angular/core";
import { HttpClientService } from "../http/http.service";
import { IUserData } from "../../models/user.model";

@Injectable({ providedIn: "root" })
export class UsersService {
  constructor(private http: HttpClientService) {}

  getAllUsers(IUserData: IUserData) {
    return this.http.get("users", IUserData);
  }
  getUserData(id) {
    return this.http.get(`users/${id}`);
  }
  createUser(body) {
    return this.http.postFormData(body, "users/create");
  }
  updateUser(body, id) {
    return this.http.postFormData(body, `users/${id}/edit`);
  }
  userActivation(id) {
    return this.http.post({}, `users/${id}/activation`);
  }
  userVerfication(id, data) {
    return this.http.post(data, `property-owner-info/${id}/verification`);
  }
  deleteUser(userId) {
    return this.http.post({}, `users/${userId}/delete`);
  }
  pointsDeduction(id, body) {
    return this.http.post(body, `users/${id}/edit-referral-points`);
  }
  addPointsToUser(id, body) {
    return this.http.post(body, `users/${id}/add-referral-points`);
  }
}
